import React from "react";

import { OMNIS_UMRTI_SLUG } from "../../constants";
import OmnisLP from "../../components/OmnisLP";
import { OmnisData } from "../../content/OmnisData";

const Umrti = () => {
  return <OmnisLP contentData={OmnisData[OMNIS_UMRTI_SLUG]} articles={[]} />;
};

export default Umrti;
